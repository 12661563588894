import { ICustomer } from "api/customers";
import React from "react";
import Image from "next/legacy/image";

interface ILogoProps {
  logoImage: ICustomer["logoImage"];
  shape: ICustomer["shape"];
  onTabSelected?: () => void;
  isLogoImageTransparent?: boolean;
}

const Logo: React.FC<ILogoProps> = ({ logoImage, isLogoImageTransparent, shape, onTabSelected }) => {
  const isCircle = shape === "circle";
  const border = isCircle ? "rounded-full" : "rounded-lg";

  return (
    <button
      onClick={onTabSelected}
      data-testid="logo"
      className={`relative h-full w-full object-contain ${shape !== "none" && border} ${
        isLogoImageTransparent && "bg-white"
      } ${!onTabSelected && "cursor-default"}`}
    >
      <Image
        src={logoImage}
        alt="Company Logo"
        className={`h-full ${shape !== "none" && "w-full"} object-contain ${shape !== "none" && border}`}
        quality="75"
        layout="fill"
      />
    </button>
  );
};

export default Logo;
