import { IJobPost } from "api/jobPosts";
import React from "react";

interface IJobCardSubTitleProps {
  children: JSX.Element;
  showCompanyName: boolean;
  employerName: IJobPost["employerName"];
}

const JobCardSubTitle = ({ children, showCompanyName, employerName }: IJobCardSubTitleProps): JSX.Element => (
  <span className="category-wrapper block font-medium text-light-grey" data-testid="job-card-subtitle">
    {showCompanyName ? `${employerName} / ` : ""}
    {children}
  </span>
);

export default JobCardSubTitle;
