import { useState, useEffect } from "react";

const useIsHydrated = (): boolean => {
  // Once useEffect() has been called, we know the app has been hydrated.
  const [isHydrated, setIsHydrated] = useState(false);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  return isHydrated;
};

export { useIsHydrated };
