import { useState, useRef, useEffect, Dispatch, SetStateAction } from "react";

const useBrowserStorage = <T,>(
  key: string,
  storage: "localStorage" | "sessionStorage" = "localStorage",
  initialState?: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    if (typeof window !== "undefined") {
      const jsonInStorage = window[storage]?.getItem(key);
      if (jsonInStorage) {
        return JSON.parse(jsonInStorage);
      }
    }

    return initialState;
  });

  const prevKeyRef = useRef(key);

  useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      window[storage].removeItem(prevKey);
    }
    prevKeyRef.current = key;
    window[storage].setItem(key, typeof state === "string" ? state : JSON.stringify(state));
  }, [key, state, storage]);

  return [state, setState];
};

export { useBrowserStorage };
