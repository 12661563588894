import { IJobPost } from "api/jobPosts";
import Skeleton from "components/Skeleton";
import { useIsHydrated } from "hooks/useIsHydrated";
import React from "react";
import { getDateListingSite } from "utils/dateHelpers";

interface IJobCardPubDateProps {
  pubDate: IJobPost["pubDate"];
}

const JobCardPubDate = ({ pubDate }: IJobCardPubDateProps): JSX.Element | null => {
  const isHydrated = useIsHydrated();

  return pubDate ? (
    <div className="ml-auto">
      <span className="text-sm text-[#BBBBBB]" data-testid="job-card-pubDate">
        {isHydrated ? getDateListingSite(pubDate) : <Skeleton>10d</Skeleton>}
      </span>
    </div>
  ) : null;
};

export default JobCardPubDate;
