import { ICustomer } from "api/customers";

export const getPlausibleDomains = (customer: ICustomer | null): string => {
  const plausibleDomains = [customer?.plausibleDomain];
  if (process.env.NEXT_PUBLIC_APP_ENV === "production") {
    plausibleDomains.push("rollup.adway.ai");
  }

  return plausibleDomains.join(",");
};
