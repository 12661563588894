import React, { FC, PropsWithChildren } from "react";

// Children should be an arbitrary text matching the intended content. The reason is that width and height will be automatically suited to the content
const Skeleton: FC<PropsWithChildren> = ({ children }) => {
  return (
    <span className={`block h-fit w-fit animate-pulse whitespace-nowrap rounded-full bg-gray-300 text-transparent`}>
      {children}
    </span>
  );
};

export default Skeleton;
