/* eslint-disable no-console */
import { differenceInCalendarDays, parseISO } from "date-fns";
import { enGB, ro, fr, sv, fi, hu, nl, pl, de } from "date-fns/locale";
import { format } from "date-fns";
import { i18n } from "next-i18next";

export const locales = { enGB, ro, fr, sv, fi, hu, nl, pl, de };
export type localeKey = keyof typeof locales;

export const formatLocalized = (date: Date, formatString?: string) =>
  format(date, formatString || "PPP", { locale: locales[(i18n?.resolvedLanguage as localeKey) ?? enGB] });

export const formatUnlocalized = (date: Date, formatString?: string) => format(date, formatString || "PPP");

export const getTimestampAsUTCDate = (timestamp: string): Date | null => {
  if (!timestamp) return null;
  return parseISO(timestamp);
};

export const displayPublishedDaysAgo = (pubDateString: string): string => {
  const pubDate = getTimestampAsUTCDate(pubDateString ?? "");
  const today = getTimestampAsUTCDate(new Date().toISOString()) as Date;
  if (!pubDate || !today) return "";

  const daysDiff = differenceInCalendarDays(today, pubDate);

  return `${daysDiff}d`;
};

export const getDateListingSite = (pubDate: string) => {
  const parsedDate = parseISO(pubDate || "");
  const today = getTimestampAsUTCDate(new Date().toISOString()) as Date;

  const differenceInDays = differenceInCalendarDays(today, parsedDate);
  const dateToDisplay =
    // TODO Check if this "On of" works for translations.
    differenceInDays < 10 ? `${differenceInDays}d` : formatLocalized(parsedDate);

  return dateToDisplay;
};
