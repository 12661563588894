import React, { FC, SVGProps } from "react";

const ShieldIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#0e0e0e"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      pointerEvents="none"
      {...props}
    >
      <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
    </svg>
  );
};

export default ShieldIcon;
