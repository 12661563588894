import React from "react";

interface IJobCardTitleProps {
  children: JSX.Element;
}

const JobCardTitle = ({ children }: IJobCardTitleProps): JSX.Element => (
  <div className="title-wrapper mb-2 text-base font-bold" data-testid="job-card-title">
    {children}
  </div>
);

export default JobCardTitle;
