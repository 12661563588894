import { IJobPost } from "api/jobPosts";
import ClockIcon from "components/icons/ClockIcon";
import { useTranslation } from "next-i18next";
import React from "react";

interface IJobCardPartTimeProps {
  isPartTime: IJobPost["isPartTime"];
}

const JobCardPartTime = ({ isPartTime }: IJobCardPartTimeProps): JSX.Element => {
  const { t: translate } = useTranslation("listingsite");

  return (
    <div className="mr-6 flex items-center justify-center">
      <span className="mr-1.5">
        <ClockIcon stroke="#666666" />
      </span>
      <span className="text-sm font-normal text-light-grey" data-testid="job-card-isPartTime">
        {isPartTime
          ? translate("jobcard.suggestions.part_time_text", "Part time")
          : translate("jobcard.suggestions.full_time_text", "Full time")}
      </span>
    </div>
  );
};

export default JobCardPartTime;
