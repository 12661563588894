import { APCAcontrast, sRGBtoY } from "apca-w3";

const CONTRAST_LIMIT = 30;

type RGB = [r: number, g: number, b: number];

export const hexToRgb = (hex: string | undefined): number[] | undefined =>
  hex?.match(/[A-Za-z0-9]{2}/g)?.map(v => parseInt(v, 16)) || [];

export const hexToHsl = (color: string | undefined, lightness?: number) => {
  const rgb = hexToRgb(color);

  if (!rgb) {
    return [];
  }
  const r = rgb[0] / 255;
  const g = rgb[1] / 255;
  const b = rgb[2] / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const diff = max - min;
  const add = max + min;

  const hue =
    min === max
      ? 0
      : r === max
      ? ((60 * (g - b)) / diff + 360) % 360
      : g === max
      ? (60 * (b - r)) / diff + 120
      : (60 * (r - g)) / diff + 240;

  const lum = 0.5 * add;

  const sat = lum === 0 ? 0 : lum === 1 ? 1 : lum <= 0.5 ? diff / add : diff / (2 - add);

  const h = Math.round(hue);
  const s = Math.round(sat * 100);
  const l = Math.round(lum * 100);

  return [h, s, lightness || l];
};

export const hslToHex = (hsl: number[]) => {
  hsl[2] /= 100;
  const a = (hsl[1] * Math.min(hsl[2], 1 - hsl[2])) / 100;
  const f = (n: number) => {
    const k = (n + hsl[0] / 30) % 12;
    const color = hsl[2] - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const darkenColor = (hexColor: string, percentage: number): string => {
  hexColor = hexColor.replace("#", "");
  const [red, green, blue] = hexToRgb(hexColor) || [];

  const newRed = Math.max(0, Math.round(red * (1 - percentage / 100)));
  const newGreen = Math.max(0, Math.round(green * (1 - percentage / 100)));
  const newBlue = Math.max(0, Math.round(blue * (1 - percentage / 100)));

  const newHexColor = `#${newRed.toString(16)}${newGreen.toString(16)}${newBlue.toString(16)}`;

  return newHexColor;
};

export const getFilterButtonBackgroundColor = (primaryColorListingSite: string | undefined) => {
  const primaryColorListingSiteHsl = hexToHsl(primaryColorListingSite, 96);
  const filterBackgroundColor = hslToHex(primaryColorListingSiteHsl);

  return filterBackgroundColor;
};

export const filterIconColor = (primaryColorListingSite: string | undefined): string | undefined =>
  filterButtonContrast(primaryColorListingSite, getFilterButtonBackgroundColor(primaryColorListingSite)) >
  CONTRAST_LIMIT
    ? primaryColorListingSite
    : "black";

export const whiteContrast = (primaryColor: string | undefined): number =>
  Math.abs(APCAcontrast(sRGBtoY([255, 255, 255]), sRGBtoY(hexToRgb(primaryColor) as RGB)) as number);
export const blackContrast = (primaryColor: string | undefined): number =>
  Math.abs(APCAcontrast(sRGBtoY([0, 0, 0]), sRGBtoY(hexToRgb(primaryColor) as RGB)) as number);

export const filterButtonContrast = (primaryColor: string | undefined, backgroundColor: string | undefined): number =>
  Math.abs(APCAcontrast(sRGBtoY(hexToRgb(primaryColor) as RGB), sRGBtoY(hexToRgb(backgroundColor) as RGB)) as number);
