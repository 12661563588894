import React, { ReactElement } from "react";

interface IJobCardWrapperProps {
  children: ReactElement;
  onClick?: () => void;
  id: string | number;
  showBackground?: boolean;
  borderStyle?: string;
}

const JobCardWrapper = ({ children, onClick, id, showBackground, borderStyle }: IJobCardWrapperProps): JSX.Element => {
  return (
    <div
      data-testid={`job-card-${id}`}
      className={`min-h-36 ${
        borderStyle || "rounded-base"
      } relative w-full break-words bg-white p-5 hover:cursor-pointer hover:bg-gray-200 active:bg-gray-300 ${
        showBackground ? "shadow-none" : "shadow-md"
      }`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default JobCardWrapper;
