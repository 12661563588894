import { useTranslation } from "next-i18next";
import React from "react";
import Location from "components/icons/LocationIcon";
import { IJobPost } from "api/jobPosts";

interface IJobCardLocationProps {
  children: JSX.Element | null;
  isRemote: boolean;
  cities: IJobPost["locations"][0]["city"][];
}

const JobCardLocation = ({ children, isRemote, cities }: IJobCardLocationProps): JSX.Element | null => {
  const { t: translate } = useTranslation(["common", "listingsite"]);

  return (
    <div className="mr-auto flex items-center justify-center">
      <span className="mr-[5px]">
        <Location stroke="#666666" width="18" height="18" />
      </span>
      <span className="location-wrapper text-sm text-light-grey" data-testid="cities">
        {isRemote
          ? translate("similarjobposts.jobcard.remote_text", "Remote")
          : cities?.length < 3
          ? children
          : translate("header.jobcard.multiple_locations_text", "Multiple locations")}
      </span>
    </div>
  );
};

export default JobCardLocation;
