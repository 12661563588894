const validFontFormats = ["ttf", "woff", "woff2", "otf", "sfnt", "ttc", "eot"];

export const getFontNameFromUrl = (url?: string): string | null => {
  if (!url) return null;
  const parts = url.split("/");
  if (!parts?.length) return null;

  const fontNameWithFileType = parts[parts.length - 1].replace(/^[0-9]+-/, "");
  if (!fontNameWithFileType) return null;

  const fontNameParts = fontNameWithFileType.split(".");
  if (!fontNameParts?.length || !validFontFormats.includes(fontNameParts[fontNameParts.length - 1])) return null;

  return decodeURIComponent(fontNameParts.slice(0, -1).join(""));
};
