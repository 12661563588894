import ShieldIcon from "components/icons/ShieldIcon";
import React from "react";

const CookieSettingsButton = (): JSX.Element => {
  return (
    <button
      data-legalmonster="show-cookie-settings"
      className="fixed bottom-32 left-[unset] right-5 flex h-15 w-15 items-center justify-center rounded-full bg-white shadow-shadow-cookies xl:left-5 xl:right-[unset]"
    >
      <ShieldIcon />
    </button>
  );
};

export default CookieSettingsButton;
